(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

;
(function () {
  var $body = $('body');
  var $lan = $('.change-lan .sub .pad');
  var autoHeight = $lan.get(0).scrollHeight;
  $('.change-lan').mouseenter(function (e) {
    var autoHeight = $lan.get(0).scrollHeight;
    $lan.css({
      "height": autoHeight
    });
    if ($body.hasClass('show-search')) {
      $body.click();
    }
  }).mouseleave(function () {
    $lan.css({
      "height": 0
    });
  });
})();

},{}],2:[function(require,module,exports){
"use strict";

var swiperBanner = new Swiper('#swiper-banner', {
  loop: true,
  speed: 500,
  observer: true,
  watchSlidesVisibility: true,
  pagination: {
    el: '#pagination-banner',
    clickable: true
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {},
    transitionEnd: function transitionEnd(i) {}
  },
  navigation: {
    nextEl: '#banner-next',
    prevEl: '#banner-prev'
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  }
});

},{}],3:[function(require,module,exports){
"use strict";

var $pushmenu;
$(".m_nav").pushmenu({
  button: "#nav_toggle"
});
$pushmenu = $('.m_nav').data('pushmenu');
$("#m-nav li").each(function () {
  var sub = $(this).find(".sub-menu-wrap");
  var link = sub.find("a").length > 0;
  if (sub && link) {
    $(this).addClass("has-sub");
    $(this).children("a").append('<i class="iconfont icon-down"></i>');
  }
});
$(".m-nav-list > li > a").click(function () {
  var sub = $(this).next(".sub-menu-wrap");
  if (!$(this).parents("li").hasClass("has-sub")) {
    return true;
  }
  if (sub.is(":visible")) {
    sub.slideUp();
    $(this).removeClass("on");
  } else {
    $("#m-nav .sub-menu-wrap").slideUp();
    $("#m-nav li .on").removeClass("on");
    $(this).addClass("on");
    sub.slideDown();
  }
  return false;
});

// 锚点点击关闭mask
$('#m-nav .sub-menu a').click(function (e) {
  if (this.hash && $(this.hash).length > 0) {
    var offset = $('.m_header').height();
    var Scroll = $(this.hash).offset().top - offset;
    $('html,body').stop(false, true).animate({
      'scrollTop': Scroll + 'px'
    }, 400);
    $('#nav_toggle').click();
  }
});

},{}],4:[function(require,module,exports){
"use strict";

;
(function () {
  var timer;
  $('.nav li').mouseenter(function () {
    var sub = $(this).find('.sub-menu-wrap');
    var subLink = sub.find('a').length > 0;
    if (sub.length && sub.find('a').length) {
      clearTimeout(timer);
      var autoHeight = sub.get(0).scrollHeight;
      $('body').addClass('show-sub');
      sub.css({
        "height": autoHeight
      });
    }
  }).mouseleave(function () {
    var sub = $(this).find('.sub-menu-wrap');
    timer = setTimeout(function () {
      $('body').removeClass('show-sub');
    }, 200);
    if (sub.length) {
      sub.css({
        "height": 0
      });
    }
  });
})();
$(window).scroll(function () {
  var isPc = $('#hd').is(':visible');
  var $hd = isPc ? $('#hd') : $('.m_header');
  if ($(window).scrollTop() > 0) {
    if (isPc) {
      $hd.addClass('small-hd');
    }
    if ($('body').hasClass('show-search')) {
      $('body').click();
    }
    if ($('body').hasClass('show-m-search')) {
      $('body').click();
    }
  } else {
    $hd.removeClass('small-hd');
  }
}).scroll();

},{}],5:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  var $hd = $('#hd');
  var $body = $('body');
  var $input = $(".search-main input");
  var $search = $('.header-search');
  $('.search-ico').click(function (e) {
    var autoHeight = $search.get(0).scrollHeight;
    if ($search.height() == 0) {
      $search.css({
        "height": autoHeight
      });
      $body.addClass('show-search');
      setTimeout(function () {
        $input.focus();
      }, 300);
    } else {
      $body.removeClass('show-search');
      $search.css({
        "height": 0
      });
    }
    return false;
  });
  $('body').click(function (e) {
    var container = $(".search-main");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      closeSearch();
    }
  });
  $('.search-main .icon-x').click(function (e) {
    closeSearch();
    return false;
  });
  function closeSearch() {
    $input.val('');
    $body.removeClass('show-search');
    $search.css({
      "height": 0
    });
  }
});

},{}],6:[function(require,module,exports){
"use strict";

require("./_nav.js");
require("./_m-nav.js");
require("./_index.js");
require("./_search.js");
require("./_change-lan.js");

},{"./_change-lan.js":1,"./_index.js":2,"./_m-nav.js":3,"./_nav.js":4,"./_search.js":5}]},{},[6]);
